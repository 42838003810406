<template>
  <v-card :loading="loading" class="d-flex flex-column">
    <v-card-title class="grid-close">
      <span class="headline"
        >{{ selectedItem ? "Editar monto" : "Nuevo monto" }}
      </span>
      <v-btn
        style="right: 10px; top: 10px; border-radius: 30px !important"
        icon
        color="primary"
        fab
        absolute
        @click="$emit('cancel')"
        v-if="!loading"
      >
        <v-icon dark>fa-times</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-snackbar
        content-class="centered"
        top
        :timeout="3000"
        v-model="snackbar"
        >{{ snackbarText }}</v-snackbar
      >
      <v-container class="pa-5">
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-text-field
              v-model.number="item.amount"
              label="Monto"
              :loading="loading"
              clearable
              v-on:keyup.enter="selectedItem ? update() : create()"
              rounded
              filled
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="pa-0 ma-0">
          <v-col cols="12" sm="12" md="12">
            <v-row justify="end">
              <v-btn
                @click="selectedItem ? update() : create()"
                :loading="loading"
                class="save-btn"
                color="buttons"
                >{{ $t("button.save") }}</v-btn
              >
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { db } from "@/firebase";
import { mapState } from "vuex";

export default {
  name: "add-offering-amount",
  props: ["selectedItem"],
  data() {
    return {
      snackbarText: "",
      snackbar: false,
      loading: true,
      item: {
        active: true,
      },
    };
  },
  computed: {
    ...mapState(["user", "groupId"]),
  },

  methods: {
    update() {
      this.loading = true;
      db.collection("offeringAmounts")
        .doc(this.selectedItem[".key"])
        .update({ amount: this.item.amount, active: this.item.active })
        .then((r) => {
          this.$emit("success");
        })
        .catch((e) => {
          this.snackbarText = e.error;
          this.snackbar = true;
          this.loading = false;
        });
    },

    create() {
      this.loading = true;
      db.collection("offeringAmounts")
        .add({
          amount: this.item.amount,
          active: this.item.active,
          groupId: this.groupId,
          deleted: false,
          createdAt: new Date(),
        })
        .then((r) => {
          this.$emit("success");
        })
        .catch((e) => {
          this.snackbarText = e.error;
          this.snackbar = true;
          this.loading = false;
        });
    },
  },
  mounted() {
    if (this.selectedItem) {
      this.item = Object.assign({}, this.selectedItem);
    }

    this.loading = false;
  },
};
</script>