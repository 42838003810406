<template>
  <v-container fluid>
    <v-snackbar
      content-class="centered"
      absolute
      top
      :timeout="3000"
      v-model="snackbar"
      >{{ snackbarText }}</v-snackbar
    >
    <v-row class="header-title-actions">
      <v-col cols="12" sm="6" md="6">
        <h1 class="page-title">Montos Predeterminados</h1>
      </v-col>

      <v-col class="align-end" cols="12" sm="6" md="6">
        <!-- <v-btn
          color="buttons"
          v-if="userDoc.role == 'sa'"
          class="mr-5"
          @click="reportDialog = true"
          :loading="loading"
        >
          <v-icon left> fas fa-chart-pie </v-icon>
          {{ $tc("screens.report") }}
        </v-btn>

        -->
        <v-btn color="buttons" @click="addDialog = true" :loading="loading">
          <v-icon left> fas fa-plus </v-icon>
          Nuevo monto
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="mt-5">
      <v-col cols="12" sm="12" md="12">
        <files-table
          @menuClick="handleMenuActions($event)"
          :items="amounts"
          @activeChange="activeChange"
          :loading="loading"
          :showEdit="true"
          :headers="headers"
          :showDelete="true"
        />
      </v-col>
    </v-row>

    <v-dialog v-if="addDialog" persistent v-model="addDialog" max-width="600px">
      <add-amount
        :selectedItem="selectedItem"
        @success="handleSuccess"
        @cancel="addDialog = false"
      />
    </v-dialog>

    <v-dialog
      v-if="deleteDialog"
      persistent
      v-model="deleteDialog"
      max-width="600px"
    >
      <Confirm
        :selectedItem="selectedItem"
        @success="hadleDelete"
        :name="selectedItem.amount"
        @cancel="deleteDialog = false"
      />
    </v-dialog>
  </v-container>
</template>
<script>
import { db, fb } from "@/firebase";
import { mapActions, mapState } from "vuex";
import filesTable from "@/components/files-table";
import addAmount from "./add-amount.vue";
import Confirm from "../../components/confirm.vue";

export default {
  name: "Offerings",
  components: { filesTable, addAmount, Confirm },
  data() {
    return {
      amounts: [],
      loading: true,
      addDialog: false,
      snackbar: false,
      snackbarText: "",
      selectedItem: null,
      deleteDialog: false,
      headers: [
        {
          text: "Monto",
          value: "amount",
          width: "150px",
        },

        {
          text: "Activo",
          value: "active",
          width: "150px",
        },

        {
          text: this.$tc("headers.createdAt"),
          value: "createdAt",
          width: "200px",
        },
        {
          value: "options",
          sortable: false,
          align: "end",
          width: "180px",
        },
      ],
    };
  },

  computed: {
    ...mapState(["groupId", "group", "userDoc"]),
  },

  methods: {
    ...mapActions(["cleanSearch"]),
    handleMenuActions(e) {
      this.selectedItem = e.item;

      switch (e.action) {
        case "edit":
          this.addDialog = true;
          break;

        case "delete":
          this.deleteDialog = true;
          break;

        default:
          break;
      }
    },
    hadleDelete() {
      this.loading = true;
      db.collection("offeringAmounts")
        .doc(this.selectedItem[".key"])
        .update({
          deleted: true,
          deletedAt: new Date(),
          deletedBy: this.userDoc[".key"],
        })
        .then(() => {
          this.loading = false;
          this.deleteDialog = false;
        })
        .catch((e) => {
          this.snackbarText = e.error;
          this.snackbar = true;
          this.loading = false;
        });
    },
    handleSuccess() {
      this.snackbarText = "Información actualizada exitosamente.";
      this.snackbar = true;
      this.addDialog = false;
      this.selectedItem = null;
    },

    async activeChange(e) {
      this.loading = true;
      await db
        .collection("offeringAmounts")
        .doc(e[".key"])
        .update({ active: e.active });

      this.loading = false;
      this.snackbarText = "Información actualizada exitosamente.";
      this.snackbar = true;
    },
  },
  async mounted() {
    await this.$binding(
      "amounts",
      db
        .collection("offeringAmounts")
        .where("groupId", "==", this.groupId)
        .where("deleted", "==", false)
    );

    this.loading = false;
  },
};
</script>