<template>
  <div>
    <v-snackbar
      content-class="centered"
      top
      :timeout="3000"
      v-model="snackbar"
      >{{ snackbarText }}</v-snackbar
    >
    <v-card class="d-flex flex-column">
      <v-card-title class="grid-close">
        <span class="headline">{{ $tc("confirm.title") }}</span>
        <v-btn
          style="right: 10px; top: 10px; border-radius: 30px !important"
          icon
          color="buttons"
          fab
          absolute
          @click="$emit('cancel')"
          v-if="!loading"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container class="pa-5">
          <v-row class="pa-0 ma-0">
            <v-col cols="12" sm="12" md="12">
              <p>
                ¿{{ $tc("confirm.are_you_sure") }}
                <span class="font-weight-bold">{{ name }}</span> ?
              </p>
            </v-col>
            <v-col v-if="message" cols="12" sm="12" md="12">
              <p>{{ message }}</p>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-row align="center" class="mb-4 mx-4">
          <v-btn
            :loading="loading"
            large
            @click="
              $emit('success');
              loading = true;
            "
            rounded
            :elevation="0"
            class="flex-grow-1 flex-shrink-0 white--text"
            :minHeight="56"
            block
            color="red darken-4"
          >
            {{ $tc("confirm.confirm_button") }}
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "confirm",
  props: ["name", "message"],
  data() {
    return {
      loading: false,
      snackbarText: "",
      snackbar: false,
    };
  },
};
</script>

<style scoped>
p {
  font-size: 18px !important;
}
</style>